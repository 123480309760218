import axios from "axios";
import $store from "@/store";
import { Message } from "element-ui";
import $router from "@/router";
const instance = axios.create(); // 创建axios实例

//为instance注册请求拦截器  发请求之前将会调用该拦截器方法
//该方法需要返回一个config参数，保存对请求数据包处理后的配置信息
instance.interceptors.request.use((config) => {
  console.log("执行了请求拦截器", config);
  // 通过config，添加一个新的消息头  Authorization
  config.headers["Authorization"] = $store.state.token;
  return config;
});

// 编写axios的响应拦截器，拦截所有响应，做统一业务异常处理
instance.interceptors.response.use(
  (res) => {
    // res就是axios响应数据对象 此为服务器正确返回的数据
    console.log("响应拦截器", res);
    //  接口没有正确返回
    if (res.data.responseCode != "000000") {
      //进行提示
      Message.warning(res.data.responseMessage);
    }
    return res;
  },
  (error) => {
    // 处理错误 此错误为请求时发生的错误 即token失效或没有token
    if (error.code == "ERR_NETWORK" || error.response.status == 403) {
      //跳转到登录页面
      $router.push("/login");
      // 弹出提示
      Message.warning("账号已失效，请重新登录");
      //清除用户信息和token
      sessionStorage.removeItem("USERINFO");
      sessionStorage.removeItem("TOKEN");
      //清除存放在state中的用户信息
      $store.state.user = null;
    }
  }
);

const myAxios = {
  /**
   * 发送一个get请求
   * @param {string} url  请求资源路径
   * @param {object} params  请求参数对象  {key:value, key2:value2}
   */
  get(url, params) {
    return instance({
      method: "GET",
      url,
      params,
    });
  },

  /**
   * 发送post请求
   * @param {string} url  请求路径
   * @param {object} params 请求参数对象 {page:1}
   */
  post(url, params) {
    return instance({
      url,
      method: "POST",
      // data: qs.stringify(params),
      data: params,
    });
  },
};
export default myAxios;
