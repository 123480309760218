/** src/http/index.js
 *  httpApi对象中封装所有的接口方法 */
import loginApi from "./apis/login";
import registerApi from "./apis/register";
import indexApi from "./apis/index";
import headerApi from "./apis/header";
import knowledgeBaseApi from "./apis/knowledgeBase";
import casesApi from "./apis/case";
import diseasePoolApi from "./apis/diseasePool";
import phenotypicPoolApi from "./apis/phenotypicPool";
const httpApi = {
    loginApi,
    registerApi,
    indexApi,
    headerApi,
    knowledgeBaseApi,
    casesApi,
    diseasePoolApi,
    phenotypicPoolApi
}

export default httpApi;