import Vue from "vue";
import Vuex from "vuex";
import httpApi from "@/http";
import $router from "@/router";
import { keys, storage } from "@/utils/storage";
import { Message } from "element-ui";
import i18n from "@/i18n/index";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    // 存储token user的值
    token: storage.get(keys.TOKEN),
    user: storage.get(keys.USERINFO),
    // captchaImage: storage.get(keys.captchaImage),
    // uuid: storage.get(keys.uuid),
    //检查localStorage里的changeLang有没有值，如果有就取值，没有就默认zh-CN
    language: localStorage.getItem("changeLang")
      ? localStorage.getItem("changeLang")
      : "zh-CN",
  },
  getters: {},
  mutations: {
    /** 保存token字符串 */
    saveToken(state, payload) {
      state.token = payload;
      // 将token存入sessionStorage
      storage.set(keys.TOKEN, payload);
    },
    // 保存用户信息
    saveUserInfo(state, payload) {
      // 将用户信息存入state
      state.user = payload;
      // 将用户信息存入sessionStorage
      storage.set(keys.USERINFO, payload);
    },
    //  // 保存验证码信息
    //  saveCaptchaImage(state, payload) {
    //   // 将验证码信息存入state
    //   state.captchaImage = payload;
    //   // 将验证码信息存入sessionStorage
    //   storage.set(keys.captchaImage, payload);
    // },
    //  // 保存用户信息
    //  saveUuid(state, payload) {
    //   // 将验证码信息存入state
    //   state.uuid = payload;
    //   // 将验证码信息存入sessionStorage
    //   storage.set(keys.uuid, payload);
    // },
    // 同步操作直接修改state里面的数据
    setLanguage: (state, data) => {
      state.language = data;
      localStorage.setItem("changeLang", data);
    },
  },
  actions: {
    // loginAction 用于 异步发送登录请求，登录成功后，将用户存入state
    // actions中的方法将会自动传入两个参数：store, payload
    // 当外部组件需要登录后存储用户信息时就可以调用这个action方法
    // loginAction(store, payload) {
    //   httpApi.loginApi.login(payload).then((res) => {
    //     // 判断响应
    //     if (res.data.responseCode == "000000") {
    //       // 登录成功后
    //       store.commit("saveToken", res.data.data.token);
    //       // 通过路由管理器 $router 跳转到首页
    //       Message.success(i18n.t(`login.loginSuccess`));
    //       $router.push("/index");
    //       // 调用获取用户信息接口，将用户信息存入state
    //       httpApi.headerApi.getLoginUserInfo().then((res) => {
    //         store.commit("saveUserInfo", res.data.data);
    //       });
    //     } else {
    //       httpApi.loginApi.getCaptchaImage().then((res) => {
    //         // 判断是否获取到图片
    //         if (res) {
    //           if (res.data.responseCode == "000000") {
    //             // 得到图片的base64编码字符串 将其转换为base64格式 然后赋值给captchaImage
    //             let image = "data:image/png;base64,"+ res.data.data.img
    //             store.commit("saveCaptchaImage", image);
    //             // 得到uuid
    //             store.commit("saveUuid", res.data.data.uuid);
    //           }
    //         }
    //       });
    //     }
    //   });
    // },
  },
  modules: {},
});
