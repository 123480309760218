const EN = {
  login: {
    accountLoginTitle: "Account login",
    verificationCodeLoginTitle: "Verification code login",
    accountInput: "Username of email",
    passwordInput: "Password",
    verificationCodeInput: "Verification code",
    phoneNumberInput: "Phone number",
    verificationCodeButton: "Send verification code",
    verificationCodeButtonClick: "Please click again after 60S",
    secondsBefore: "Please click again after ",
    secondsAfter: "S",
    logInButton: "log in",
    accountRegistrationButton: "Account registration",
    agreeDetailText: "I have read it in detail and agree",
    userAgreementButton: "User agreement",
    closeButton: "close",
    inputPhoneNumberTips: "Please enter your phone number",
    inputCorrectPhoneNumber: "Please enter the correct phone number",
    SMSSendText: "SMS sent",
    showverificationCode:"The verification code is ",
    loginSuccess:"login successfully",
  },
  header:{
    searchInput:"please enter",
    welcomeText:"Welcome! ",
    logoutButton:"logout",
    logInButton: "log in",
    ChineseEnglishSwitchButton:"Chinese/English switch",
    homePageButton:"Home",
    knowledgeBaseButton:"Database",
    dataBaseButton:"Databases",
    aboutButton:"Oous",
    contactUsButton:"contact us",
    protocolOfUsageButton:"protocol of usage",
    copyrightButton:"copyright",
    logoutText:"Logout successful",
  },
  register:{
    registerTitle:"register",
    emailTitle:"email:",
    emailTips:"Please enter your email address",
    mobilePhoneTitle:"Phone number:",
    mobilePhoneTips:"Please enter your phone number",
    passwordTitle:"password:",
    passwordTips:"Please enter the password, ≥8 characters, including at least alphanumeric",
    confirmPasswordTitle:"Confirm Password:",
    confirmPasswordTips:"Please enter your password again",
    companyTitle:"unit information:",
    companyTips:"Please enter the unit information",
    deptIdTitle:"Department information:",
    deptIdTips:"Please enter department information",
    realNameTitle:"real name:",
    realNameTips:"Please enter your real name",
    checkPasswordText:"Two password inconsistencies!",
    checkPhoneText:"The phone number has been registered!",
    checkEmailText:"This email has been registered!",
    emailRulesText:"Please enter email!",
    mobilePhoneRulesText:"Please enter your phone number!",
    checkMobilePhoneRulesText:"Please fill in the mobile phone number correctly!",
    passwordRulesText:"Please enter password!",
    registerSuccessText:"registered successfully!",
  }
};
export default EN;
