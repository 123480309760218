<template>
  <!-- 整体容器 高度为视窗的100% -->
  <el-container style="height: 100vh">
    <!-- header部分 -->
    <el-header class="header">
      <!-- 用作处理布局 左右部分各处两端-->
      <div style="display: flex; justify-content: space-between">
        <!-- header左边部分 -->
        <div class="left">
          <!-- logo图片 -->
          <img
            style="width: 60px; height: 60px; border-radius: 5px"
            src="../../public/icon.png"
          />
          <!-- 标题文字 -->
          <div class="title">
            <div class="titlePublic big">单基因糖尿病知识库</div>
            <div class="titlePublic small">Diabetes Genetic Database</div>
          </div>
        </div>
        <!-- 右边部分 -->
        <div class="right">
          <!-- 上部分 -->
          <div class="top">
            <!-- 输入框 宽度写行内才不影响布局 -->
            <el-input
              style="width: 300px"
              class="search"
              :placeholder="$t('header.searchInput')"
              suffix-icon="el-icon-search"
              v-model="search"
            >
            </el-input>
            <!-- 根据用户是否登录显示不同的按钮 登录后显示有退出登录下拉菜单的-->
            <el-dropdown v-if="$store.state.user" trigger="click">
              <div
                style="
                  background: #025478;
                  height: 30px;
                  color: #fff;
                  font-size: 12px;
                  line-height: 30px;
                "
              >
                <i class="el-icon-user-solid icon"></i>
                {{ $t("header.welcomeText")
                }}{{
                  $store.state.user.realName
                    ? $store.state.user.realName
                    : $store.state.user.email
                }}
              </div>
              <!-- <button class="button" style="font-size: 20px">
                {{ $store.state.user.email }} -->
              <!-- 下拉图标 font-size是为了控制图标的大小-->
              <!-- <i
                  class="el-icon-caret-bottom el-icon--right"
                  style="font-size: 30px"
                ></i> -->
              <!-- </button>  -->
              <!-- 下拉菜单 -->
              <el-dropdown-menu slot="dropdown">
                <!-- 退出登录按钮 -->
                <button class="button" @click="exit">
                  {{ $t("header.logoutButton") }}
                </button>
              </el-dropdown-menu>
            </el-dropdown>
            <!-- 根据用户是否登录显示不同的按钮 没有登录显示登录按钮-->
            <button
              v-if="!$store.state.user"
              class="button"
              @click="$router.push('/login')"
            >
              {{ $t("header.logInButton") }}
            </button>
            <button class="button" @click="handleSetLanguage">
              {{ $t("header.ChineseEnglishSwitchButton") }}
            </button>
          </div>
          <!-- 下部分 -->
          <div class="bottom">
            <!-- 三个按钮 -->
            <button class="button" @click="$router.push('/index')">
              {{ $t("header.homePageButton") }}
            </button>
            <button
              class="button"
              @click="$router.push('/database/diseasePool')"
            >
              {{ $t("header.knowledgeBaseButton") }}
            </button>
            <button
              class="button"
              @click="$router.push('/database/knowledgeBaseList/0')"
            >
              {{ $t("header.dataBaseButton") }}
            </button>
            <!-- 下拉菜单整体 -->
            <el-dropdown>
              <button class="button">
                {{ $t("header.aboutButton") }}
                <!-- 下拉图标 font-size是为了控制图标的大小-->
                <i
                  class="el-icon-caret-bottom el-icon--right"
                  style="font-size: 16px"
                ></i>
              </button>
              <!-- 下拉菜单 -->
              <el-dropdown-menu slot="dropdown">
                <!-- 三个按钮 -->
                <button class="button" @click="$router.push('/contactus')">
                  {{ $t("header.contactUsButton") }}
                </button>
                <!-- display: block是为了让三个按钮竖着排列 -->
                <button
                  class="button"
                  style="display: block"
                  @click="$router.push('/protocolofusage')"
                >
                  {{ $t("header.protocolOfUsageButton") }}
                </button>
                <button class="button" @click="$router.push('/copyright')">
                  {{ $t("header.copyrightButton") }}
                </button>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
      </div>
    </el-header>
    <!-- 中间的主体内容高度为视窗的100% - header的高度 - footer的高度 -->
    <router-view style="height: calc(100vh - 70px - 60px)"></router-view>
    <!-- footer部分 -->
    <el-footer class="footer"
      >Copyright@上海市第六人民医院/上海市糖尿病研究所 <br />
      备案编号： <a href="https://beian.miit.gov.cn/" target="_blank" class="beian">沪ICP备18042271号-2</a>
      </el-footer
    >
  </el-container>
</template>

<script>
import httpApi from "@/http";

export default {
  data() {
    return {
      // 存放搜索框的值
      search: "",
      // 存放语言
      language: this.$store.state.language,
    };
  },
  methods: {
    //切换语言
    handleSetLanguage() {
      //判断当前语言是中文还是英文
      if (this.language == "zh-CN") {
        //切换成英文
        this.language = "en-US";
      } else {
        //切换成中文
        this.language = "zh-CN";
      }
      //切换语言
      this.$i18n.locale = this.language;
      //将语言存放在state中
      this.$store.commit("setLanguage", this.language);
    },
    //退出登录
    exit() {
      //发起请求
      httpApi.headerApi.loginOut().then((res) => {
        //判断是否有返回
        if (res) {
          //判断是否退出成功
          if (res.data.responseCode == "000000") {
            //提示退出成功
            this.$message.success(this.$t("header.logoutText"));
            //跳转到登录页面
            this.$router.push("/login");
            //清除用户信息和token
            sessionStorage.removeItem("USERINFO");
            sessionStorage.removeItem("TOKEN");
            //清除存放在state中的用户信息
            this.$store.state.user = null;
            //清除存放在state中的token
            this.$store.state.token = null;
          }
        }
      });
    },
  },
  mounted() {
    console.log(this.language);
  },
};
</script>

<style lang="scss" scoped>
// 给header设置背景颜色 清除padding 设置高度
.header {
  background-color: #025478;
  padding: 0;
  height: 70px !important;
  //左边部分设置上和左外边距 布局设置为弹性盒
  .left {
    display: flex;
    margin-left: 20px;
    margin-top: 5px;
    //给标题文字整体设置了一个左外边距 设置页面缩放时文字不换行
    .title {
      margin-left: 10px;
      white-space: nowrap;
      //给标题统一设置为白色
      .titlePublic {
        color: #fff;
      }
      //给大号标题设置字重和字体大小
      .big {
        font-size: 20px;
        font-weight: 700;
      }
      //给小号标题设置字重和字体大小 上外边距
      .small {
        font-size: 16px;
        font-weight: 600;
        margin-top: 5px;
      }
    }
  }
  //右边部分设置高度 设置弹性盒 修改弹性方向为列 再进行两端对齐
  .right {
    height: 67px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    //右上部分设置上外边距 弹性盒 垂直方向剧中对齐
    .top {
      display: flex;
      margin-top: 3px;
      align-items: center;
    }
    //设置为弹性盒 尾端对齐 紧靠右边页面
    .bottom {
      display: flex;
      justify-content: flex-end;
    }
    //按钮样式 设置背景颜色 清空border  设置宽高 修改字体颜色 修改字体大小
  }
}
.button {
  background: #025478;
  border: 0;
  height: 30px;
  width: 100px;
  color: #fff;
  font-size: 14px;
}
// 给按钮添加悬浮时的效果
.button:hover {
  color: #409eff;
  background-color: #ecf5ff;
}
//清除下拉菜单的上外边距
.el-popper[x-placement^="bottom"] {
  margin-top: 0;
}
//清除下拉菜单的内边距
.el-dropdown-menu {
  padding: 0;
}
//修改输入框背景颜色 高度
::v-deep .search .el-input__inner {
  background-color: #575554;
  color: #fff;
  //高度必须写这里才生效
  height: 24px;
  //设置placeholder的字体大小
  font-size: 12px;
}
//给输入框中的搜索图标设置宽度 防止偏移 设置字体大小放大图标 设置行高使图标垂直居中
::v-deep .el-input__icon,
.el-icon-search {
  width: 30px;
  // font-size: 16px;
  line-height: 24px;
}
//去除下拉菜单的边框
// .el-dropdown-menu {
//   border: 0;
// }
//设置footer的背景颜色 字体颜色 设置文字水平居中 设置行高以达到垂直居中 设置zindexs是为了防止有东西覆盖在上面
.footer {
  background-color: #035f87;
  line-height: 30px;
  color: #fff;
  text-align: center;
  z-index: 5;
  .beian {
    color: #fff;
    //去掉下划线
    text-decoration: none;
  }
}
</style>
