import baseUrl from "../baseUrl";
import myAxios from "../myAxios";
const URL = baseUrl.URL; //数据服务接口前缀

/** 封装所有的接口方法 */
const registerApi = {
  //注册接口
  register(params) {
    let url = URL + "/public/register";
    return myAxios.post(url, params);
  },
  //查询手机号是否注册
  checkPhoneUnique(params) {
    let url = URL + "/public/checkPhoneUnique";
    return myAxios.post(url, params);
  },
  //查询邮箱是否注册
  checkEmailUnique(params) {
    let url = URL + "/public/checkEmailUnique";
    return myAxios.post(url, params);
  },
};

export default registerApi;
