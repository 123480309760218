import baseUrl from "../baseUrl";
import myAxios from "../myAxios";
const URL = baseUrl.URL; //数据服务接口前缀

/** 封装所有的接口方法 */
const knowledgeBaseApi = {
  //基因列表查询
  geneListQuery(params) {
    let url = URL + "/singleDiabetes/list";
    return myAxios.post(url, params);
  },
  //基因列表搜索
  geneListSearch(params) {
    let url = URL + "/singleDiabetes/searchList";
    return myAxios.post(url, params);
  },
  //获取基因详情
  getGeneDetail(params) {
    let url = URL + "/singleDiabetes/geneDetailQry";
    return myAxios.post(url, params);
  },
  //导入文件
  importFile(params) {
    let url = URL + "/singleDiabetes/importGeneData";
    return myAxios.post(url, params);
  },
  //修改基因详情
  updataGeneDetail(params) {
    let url = URL + "/singleDiabetes/update";
    return myAxios.post(url, params);
  },
  //基因删除
  geneDelById(params) {
    let url = URL + "/singleDiabetes/geneDelById";
    return myAxios.post(url, params);
  },
  //获取基因类型
  geneTypeListQry(params) {
    let url = URL + "/singleDiabetes/geneTypeListQry";
    return myAxios.post(url, params);
  },
  //基因信息添加
  geneAdd(params) {
    let url = URL + "/singleDiabetes/geneAdd";
    return myAxios.post(url, params);
  },
};

export default knowledgeBaseApi;
