// src/utils/storage.js  WebStorage存储工具

/** keys 用于定义存储在SessionStorage中所有的key */
export const keys = {
  TOKEN: 'TOKEN',
  USERINFO: 'USERINFO',
  // captchaImage: 'captchaImage',
  // uuid: 'uuid',
  // LANGUAGE: 'LANGUAGE',
}

export const storage = {
  /** 向sessionStorage中存数据 */
  set(key, value){
    sessionStorage.setItem(key, JSON.stringify(value))
  },
  
  /** 从sessionStorage中取数据 */
  get(key){
    let value = sessionStorage.getItem(key)
    return JSON.parse(value)
  }
}
