import baseUrl from "../baseUrl";
import myAxios from "../myAxios";
const URL = baseUrl.URL; //数据服务接口前缀

/** 封装所有的接口方法 */
const casesApi = {
  //病例列表查询
  casesListQuery(params) {
    let url = URL + "/cases/list";
    return myAxios.post(url, params);
  },

  //获取病例详情
  getCasesDetail(params) {
    let url = URL + "/cases/casesDetail";
    return myAxios.post(url, params);
  },

  //病例删除
  casesDel(params) {
    let url = URL + "/cases/casesDel";
    return myAxios.post(url, params);
  },

  //病例新增
  casesAdd(params) {
    let url = URL + "/cases/casesAdd";
    return myAxios.post(url, params);
  },

  //病例修改
  casesUpdate(params) {
    let url = URL + "/cases/casesUpdate";
    return myAxios.post(url, params);
  },

  //病例导入
  importCasesData(params) {
    let url = URL + "/cases/importCasesData";
    return myAxios.post(url, params);
  },
};

export default casesApi;
