import baseUrl from "../baseUrl";
import myAxios from "../myAxios";
const URL = baseUrl.URL; //数据服务接口前缀

/** 封装所有的接口方法 */
const headerApi = {
  //查询用户信息
  getLoginUserInfo(params) {
    let url = URL + "/user/getUserInfo";
    return myAxios.post(url, params);
  },
  //退出登录
  loginOut(params) {
    let url = URL + "/user/loginOut";
    return myAxios.post(url, params);
  },
};

export default headerApi;
