import baseUrl from "../baseUrl";
import myAxios from "../myAxios";
const URL = baseUrl.URL; //数据服务接口前缀

/** 封装所有的接口方法 */
const phenotypicPoolApi = {
  //表型列表查询
  phenotypeListQuery(params) {
    let url = URL + "/phenotype/list";
    return myAxios.post(url, params);
  },
  //表型信息删除
  phenotypeInfoDel(params) {
    let url = URL + "/phenotype/phenotypeDel";
    return myAxios.post(url, params);
  },
  //表型信息新增和修改
  phenotypeHandler(params) {
    let url = URL + "/phenotype/phenotypeHandler";
    return myAxios.post(url, params);
  },
  //表型搜索
  phenotypeSearch(params) {
    let url = URL + "/phenotype/phenotypeListSearch";
    return myAxios.post(url, params);
  },
};

export default phenotypicPoolApi;
