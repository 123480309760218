import baseUrl from "../baseUrl";
import myAxios from "../myAxios";
const URL = baseUrl.URL; //数据服务接口前缀

/** 封装所有的接口方法 */
const loginApi = {
  //获取验证码接口
  getCaptchaImage(params) {
    let url = URL + "/captcha/captchaImage";
    return myAxios.get(url, params);
  },
  //登录接口
  login(params) {
    let url = URL + "/user/login";
    return myAxios.post(url, params);
  },
  //发送短信验证码
  sendVerificationCode(params) {
    let url = URL + "/smsCode/sendCode";
    return myAxios.post(url, params);
  },
};

export default loginApi;
