const zh_CN = {
  login: {
    accountLoginTitle: "账号登录",
    verificationCodeLoginTitle: "验证码登录",
    accountInput: "请输入账号",
    passwordInput: "请输入密码",
    verificationCodeInput: "请输入验证码",
    phoneNumberInput: "请输入手机号",
    verificationCodeButton: "发送验证码",
    verificationCodeButtonClick: "请在60秒后再点击",
    secondsBefore: "请在",
    secondsAfter: "秒后再点击",
    logInButton: "登录",
    accountRegistrationButton: "账号注册",
    agreeDetailText: "我已详细阅读并同意",
    userAgreementButton: "用户使用协议",
    closeButton: "关闭",
    inputPhoneNumberTips: "请输入手机号",
    inputCorrectPhoneNumber: "请输入正确的手机号",
    SMSSendText: "短信已发送",
    showverificationCode:"验证码为 ",
    loginSuccess:"登录成功",
  },
  header:{
    searchInput:"请输入",
    welcomeText:"欢迎您，",
    logoutButton:"注销",
    logInButton: "登录",
    ChineseEnglishSwitchButton:"中/英切换",
    homePageButton:"首页",
    knowledgeBaseButton:"知识库",
    dataBaseButton:"数据库",
    aboutButton:"关于",
    contactUsButton:"联系我们",
    protocolOfUsageButton:"使用协议",
    copyrightButton:"版权",
    logoutText:"注销成功",
  },
  register:{
    registerTitle:"注 册",
    emailTitle:"邮 箱:",
    emailTips:"请输入您的邮箱",
    mobilePhoneTitle:"手机号:",
    mobilePhoneTips:"请输入您的手机号",
    passwordTitle:"密 码:",
    passwordTips:"请输入密码，≥8位，至少包含字母数字",
    confirmPasswordTitle:"重复密码:",
    confirmPasswordTips:"请再次输入您的密码",
    companyTitle:"单位信息:",
    companyTips:"请输入单位信息",
    deptIdTitle:"部门信息:",
    deptIdTips:"请输入部门信息",
    realNameTitle:"真实姓名:",
    realNameTips:"请输入真实姓名",
    checkPasswordText:"两次输入密码不一致!",
    checkPhoneText:"该手机号已被注册!",
    checkEmailText:"该邮箱已被注册!",
    emailRulesText:"请输入邮箱!",
    mobilePhoneRulesText:"请输入手机号!",
    checkMobilePhoneRulesText:"请正确填写手机号!",
    passwordRulesText:"请输入密码!",
    registerSuccessText:"注册成功!",
  }
};
export default zh_CN;
