import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

//引入ElementUI
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
Vue.use(ElementUI);

//引入多语言注册文件
import i18n from '@/i18n/index';

Vue.config.productionTip = false;

//引入dayjs
import dayjs from "dayjs";
//全局注册
Vue.prototype.dayjs = dayjs;

//引入md5
import md5 from 'js-md5';
//全局注册
Vue.prototype.$md5 = md5;

new Vue({
  i18n,//引入i18n
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
