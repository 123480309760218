import baseUrl from "../baseUrl";
import myAxios from "../myAxios";
const URL = baseUrl.URL; //数据服务接口前缀

/** 封装所有的接口方法 */
const diseasePoolApi = {
  //疾病列表查询
  diseaseListQuery(params) {
    let url = URL + "/disease/list";
    return myAxios.post(url, params);
  },
  //疾病信息删除
  diseaseInfoDel(params) {
    let url = URL + "/disease/diseaseDel";
    return myAxios.post(url, params);
  },
  //疾病信息新增和修改
  diseaseHandler(params) {
    let url = URL + "/disease/diseaseHandler";
    return myAxios.post(url, params);
  },
  //疾病搜索
  diseaseSearch(params) {
    let url = URL + "/disease/diseaseSearch";
    return myAxios.post(url, params);
  },
};

export default diseasePoolApi;
