import baseUrl from "../baseUrl";
import myAxios from "../myAxios";
const URL = baseUrl.URL; //数据服务接口前缀

/** 封装所有的接口方法 */
const indexApi = {
  //首页信息获取
  getInfo(params) {
    let url = URL + "/home/homeInfo";
    return myAxios.post(url, params);
  },
};

export default indexApi;
