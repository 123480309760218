// http/baseUrl.js  此处声明两套环境

const ENV = {
    DEV: {
      // 开发环境
      // URL: process.env.VUE_APP_BASE_API,
      URL: "/prod-api",
      UPLOADURL: "/prod-api",
    },
    PRO: {
      // 生产
      URL: "",
      UPLOADURL: "",
    },
  };
  export default ENV.DEV;
  