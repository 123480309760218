import Vue from "vue";
import VueRouter from "vue-router";
import header from "../views/header.vue";
import $store from "@/store";
import { Message } from "element-ui";

Vue.use(VueRouter);

const routes = [
  // {
  //   path: '/',
  //   name: 'home',
  //   component: HomeView
  // },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
  //根路由重定向为login
  {
    path: "/",
    redirect: "/login",
  },
  {
    path: "/",
    name: "home",
    component: header,
    children: [
      {
        path: "register",
        component: () => import("../views/register.vue"),
      },
      {
        path: "index",
        component: () => import("../views/index.vue"),
      },
      {
        path: "contactus",
        component: () => import("../views/about/contactUs.vue"),
      },
      {
        path: "copyright",
        component: () => import("../views/about/copyright.vue"),
      },
      {
        path: "protocolofusage",
        component: () => import("../views/about/protocolOfUsage.vue"),
      },
      {
        path: "database",
        component: () => import("../views/aside.vue"),
        redirect: "/database/knowledgeBaseList/0",
        children: [
          {
            path: "knowledgeBaseList/:type",
            component: () => import("../views/knowledgeBase/list.vue"),
          },
          {
            path: "knowledgeBaseDetail/:geneId",
            component: () => import("../views/knowledgeBase/detail.vue"),
          },
          {
            path: "knowledgeBaseAdd",
            component: () => import("../views/knowledgeBase/add.vue"),
          },
          {
            path: "caseList",
            component: () => import("../views/case/list.vue"),
          },
          {
            path: "caseDetail/:casesId",
            component: () => import("../views/case/detail.vue"),
          },
          {
            path: "caseAdd",
            component: () => import("../views/case/add.vue"),
          },
          {
            path: "diseasePool",
            component: () => import("../views/diseasePool.vue"),
          },
          {
            path: "phenotypicPool",
            component: () => import("../views/phenotypicPool.vue"),
          },
          // {
          //   path: "test",
          //   component: () => import("../views/AboutView.vue"),
          // },
        ],
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/login.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// 为router路由管理器注册全局前置守卫，
// 一旦有路由跳转行为发生，就会在跳转到目标组件之前执行这个回调方法
router.beforeEach((to, from, next) => {
  console.log({ to, from, next });
  if (
    to.path == "/login" ||
    to.path == "/register" ||
    to.path == "/contactus" ||
    to.path == "/copyright" ||
    to.path == "/protocolofusage" ||
    $store.state.token
  ) {
    // 手动调用next方法 意味着继续向后执行真正的路由跳转功能
    next();
  } else {
    // 判断vuex中是否有登录用户
    next("/login");
    Message.warning("暂无登录状态，请重新登录");
  }
  //如果要去caselist或者caseDetail页面，则需要判断该用户是否为admin
  if (to.path === "/database/caselist" || to.path.match(/^\/database\/caseDetail\/(\d+)$/)) {
    if ($store.state.user.role != "admin") {
      Message.warning("您没有权限访问该页面");
      next("/login");
    }
  }
});

export default router;
